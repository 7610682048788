<template>
  <div id="submenuSwiper">
    <swiper :options="options">
      <swiper-slide
        class="menu-list"
        v-for="item in content"
        :key="item.number"
      >
        <div class="menu-item">
          <router-link :to="{ name: item.link }">{{ item.title }}</router-link>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-work">
      <div class="swiper-button-prev" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default {
  name: 'SubmenuSwiper',
  data() {
    return {
      options: {
        slidesPerView: 1.5,
        loop: true,
        spaceBetween: 10,
        navigation: {
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      },
      content: [
        {
          number: '01',
          title: 'Executive Recruitment',
          text: '',
          link: 'work.executive-recruitment',
        },
        {
          number: '02',
          title: 'Succession Planning',
          text: '',
          link: 'work.succession-planning',
        },
        {
          number: '03',
          title: 'Transaction Support',
          text: '',
          link: 'work.transaction-support',
        },
        {
          number: '04',
          title: 'Professional Development',
          text: '',
          link: 'work.professional-development',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
