<template>
  <main>
    <nav
      v-if="isMobile == true"
      class="submenu-slider"
      data-scroll-section
      data-scroll="fade up"
    >
      <SubmenuSwiper />
    </nav>
    <nav v-else class="work-menu-tabs" data-scroll-section>
      <ul class="menu-list" data-scroll="fade-up">
        <li class="menu-item">
          <router-link :to="{ name: 'work.executive-recruitment' }"
            >Executive Recruitment</router-link
          >
        </li>
        <li class="menu-item">
          <router-link :to="{ name: 'work.succession-planning' }"
            >Succession Planning</router-link
          >
        </li>
        <li class="menu-item">
          <router-link :to="{ name: 'work.transaction-support' }"
            >Transaction Support</router-link
          >
        </li>
        <li class="menu-item">
          <router-link :to="{ name: 'work.professional-development' }"
            >Professional Development</router-link
          >
        </li>
      </ul>
    </nav>
    <transition name="fade">
      <router-view @view-mounted="$emit('view-mounted')" />
    </transition>
  </main>
</template>

<script>
import SubmenuSwiper from './SubmenuSwiper';

export default {
  name: 'Work',
  metaInfo() {
      return { 
          title: "Ascentador | The Work",
          meta: [
              { name: 'description', content:  'The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project. Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions.'},
          ]
      }
  },
  components: {
    SubmenuSwiper,
  },
  data() {
    return {
      isMobile: undefined,
    };
  },
  methods: {
    windowChangedSize() {
      if (innerWidth <= 980) this.isMobile = true;
      else this.isMobile = false;
    },
  },
  created() {
    window.addEventListener('resize', this.windowChangedSize);
  },
  mounted() {
    this.windowChangedSize();
    this.$emit('view-mounted');
  },
  destroyed() {
  window.removeEventListener("resize", this.windowChangedSize);
},
};
</script>
